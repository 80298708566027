/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import $ from 'jquery'
import PrimaryLayout from '../components/layout/primary-layout'
import SEO from '../components/seo'
import HeroSection from '../components/home/hero-section'
import WhatWeDo from '../components/home/what-we-do'
import TechnologiesWeKnow from '../components/home/technologies-we-know'
import Portfolio from '../components/home/portfolio-section'
import MarketingTools from '../components/home/marketing-tools-section'
import ThingsWeWorkWith from '../components/home/things-we-work-with/index'
import { JsonLd } from '../components/seo/JsonLd'

const IndexPage = ({ data, location }) => {
  const metaData = data.allContentfulHomePage.edges[0].node

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': 'https://banbanmedia.co/',
        name: 'Home',
      },
      position: 1,
    },
  ]
  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const address = [
    {
      '@type': 'PostalAddress',
      addressLocality: 'Stockholm',
      addressRegion: 'Sweden',
      streetAddress: 'Klara Norra kyrkogata 26',
      postalCode: '11122',
    },
  ]

  const localBusiness = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'Banban',
    description:
      'We made this page to show you what we know and what we can do for you.',
    telephone: '886905883127',
    email: 'hello@banbanmedia.co',
    sameAs: 'https://www.facebook.com/henkka.segersven',
    image: '/static/logo/banban-logo.png',
    url: 'https://banbanmedia.co/',
    priceRange: '$',
    address,
  }

  const website = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    about:
      'We made this page to show you what we know and what we can do for you.',
    url: 'https://banbanmedia.co',
    name: 'Banban',
  }

  const speakable = [
    {
      '@type': 'SpeakableSpecification',
      cssSelector: '.cms-content',
      xpath: '/html/head/title',
    },
  ]

  const webPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    speakable,
  }

  const [isDocReady, setIsDocReady] = useState(false)
  const isBrowser = typeof document !== 'undefined'

  if (isBrowser) {
    $(document).ready(() => {
      setIsDocReady(true)
      console.log('Document Ready!')
    })
  }

  return (
    <PrimaryLayout location={false}>
      <SEO title="Home" description={metaData.metaDescription} />
      <JsonLd>{localBusiness}</JsonLd>
      <JsonLd>{breadcrumb}</JsonLd>
      <JsonLd>{website}</JsonLd>
      <JsonLd>{webPage}</JsonLd>
      <HeroSection location={false} isDocReady={isDocReady} />
      <WhatWeDo location={false} isDocReady={isDocReady} />
      <TechnologiesWeKnow location={false} />
      <Portfolio location={false} />
      <MarketingTools location={false} />
      <ThingsWeWorkWith location={false} isDocReady={isDocReady} />
    </PrimaryLayout>
  )
}

export const query = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          metaDescription
        }
      }
    }
  }
`

export default IndexPage
