/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react'
import lottie from 'lottie-web'
import LazyLoad from 'react-lazyload'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useStaticQuery, graphql } from 'gatsby'
import { filterLanguage } from '../../utils/language'
import animatedImage from '../../../../static/animations/image-5.json'
import './_things-we-work-with.scss'

const ThingsWeWorkWith = ({ location, isDocReady }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        nodes {
          node_locale
          id
          thingsWeWorkWithImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWithTitle
          thingsWeWorkWith1stImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith2ndImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith3rdImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith4thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith5thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith6thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith7thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith8thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith9thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          thingsWeWorkWith10thImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `)

  const { nodes } = data.allContentfulHomePage
  const nodeslang = filterLanguage(location, nodes)
  const animationContainer = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const [animationLoaded, setAnimationLoaded] = useState(false)

  useEffect(() => {
    if (animationContainer.current) {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animatedImage,
      })

      setAnimationLoaded(true)

      if (inView === true) {
        anim.play()
      }

      return () => anim.destroy()
    }
  }, [animationContainer, inView])

  return (
    <section id="things-we-work-section" ref={ref}>
      {nodeslang.map((item) => (
        <div className="container" key={item.id}>
          <div className="row pb-3 justify-content-center">
            <div className="col-md-8">
              {!animationLoaded && <img style={{ minHeight: '406px', width: '100%' }} src="./home/3-people.png" alt="3 people" />}
              {isDocReady && <div style={{ minHeight: `${isDocReady && animationLoaded ? '406px' : '0'}` }} className="animation-container" ref={animationContainer} />}
            </div>
          </div>

          <div className="row pt-5 justify-content-center">
            <div className="col-md-7 things-we-work-head">
              <h1>{item.thingsWeWorkWithTitle}</h1>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith1stImage.fluid}
                  alt={item.thingsWeWorkWith1stImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith2ndImage.fluid}
                  alt={item.thingsWeWorkWith2ndImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith3rdImage.fluid}
                  alt={item.thingsWeWorkWith3rdImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith4thImage.fluid}
                  alt={item.thingsWeWorkWith4thImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith5thImage.fluid}
                  alt={item.thingsWeWorkWith5thImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith6thImage.fluid}
                  alt={item.thingsWeWorkWith6thImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith7thImage.fluid}
                  alt={item.thingsWeWorkWith7thImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith8thImage.fluid}
                  alt={item.thingsWeWorkWith8thImage.file.fileName}
                />
              </LazyLoad>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith9thImage.fluid}
                  alt={item.thingsWeWorkWith9thImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 col-6 pt-4">
              <LazyLoad>
                <Img
                  fluid={item.thingsWeWorkWith10thImage.fluid}
                  alt={item.thingsWeWorkWith10thImage.file.fileName}
                />
              </LazyLoad>
            </div>
          </div>

          <div className="row text-center mt-5">
            <div className="col-12 things-we-work-cta">
              <button
                onClick={() => scrollTo('#work-together')}
                className="btn"
              >
                Work Together
              </button>
            </div>
          </div>
        </div>
      ))}
    </section>
  )
}

export default ThingsWeWorkWith
