/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import lottie from 'lottie-web'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import makeWebsiteAnimation from '../../../../static/animations/image-1.json'

const MakeWebsite = ({ item, isDocReady }) => {
  const animationContainer = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const [animationLoaded, setAnimationLoaded] = useState(false)

  useEffect(() => {
    if (animationContainer.current) {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: makeWebsiteAnimation,
      })

      setAnimationLoaded(true)

      if (inView === true) {
        anim.play()
      }

      return () => anim.destroy()
    }
  }, [animationContainer, inView])

  return (
    <div className="row pt-4 pb-4" ref={ref}>
      <div className="col-md-6 what-we-do-img">
        {!animationLoaded && <img style={{ minHeight: '443px', width: '100%' }} src="./home/make-website.png" alt="Make Website" />}
        {isDocReady && <div style={{ minHeight: `${isDocReady && animationLoaded ? '443px' : '0'}` }} className="animation-container" ref={animationContainer} />}
      </div>
      <div className="col-md-6 mt-auto mb-auto what-we-do-content">
        <p>
          <span># 1</span>
        </p>
        <h2>{item.firstProcessTitle}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html:
              item.firstProcessDescription.childContentfulRichText.html,
          }}
        />
      </div>
    </div>
  )
}

export default MakeWebsite
