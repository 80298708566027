/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import lottie from 'lottie-web'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStaticQuery, graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { filterLanguage } from '../../utils/language'
import heroAnimatedImage from '../../../../static/animations/banner.json'
import './_hero.scss'

const HeroSection = ({ location, isDocReady }) => {
  const data = useStaticQuery(graphql`
    query HeroSection {
      allContentfulHomePage {
        nodes {
          id
          node_locale
          heroTitle
          HeroDescription {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  `)

  const { nodes } = data.allContentfulHomePage
  const nodeslang = filterLanguage(location, nodes)
  const animationContainer = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })
  const [animationLoaded, setAnimationLoaded] = useState(false)

  useEffect(() => {
    if (animationContainer.current) {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: heroAnimatedImage,
      })

      setAnimationLoaded(true)

      if (inView === true) {
        anim.play()
      }

      return () => anim.destroy()
    }
  }, [animationContainer, inView])

  return (
    <section id="home-hero-section" ref={ref}>
      <div className="container">
        {nodeslang.map((item) => (
          <div key={item.id}>
            <div className="row">
              <div className="col-md-6 home-hero-text mt-auto mb-auto">
                <h1>{item.heroTitle}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.HeroDescription.childContentfulRichText.html,
                  }}
                />
                <button
                  onClick={() => scrollTo('#work-together')}
                  className="btn"
                >
                  Work Together
                </button>
              </div>

              <div className="col-md-6 text-center">
                {!animationLoaded && <img style={{ minHeight: '485px', width: '100%' }} src="./home/hero-img.png" alt="Hero" />}
                {isDocReady && <div style={{ minHeight: `${isDocReady && animationLoaded ? '485px' : '0'}` }} className="animation-container" ref={animationContainer} />}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default HeroSection
