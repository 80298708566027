/* eslint-disable react/button-has-type */
import React from "react";
import LazyLoad from "react-lazyload";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useStaticQuery, graphql } from "gatsby";
import "./_marketing-tools.scss";
import { filterLanguage } from "../../utils/language";
const MarketingTools = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        nodes {
          id
          node_locale
          sellYourStuffTitle
          sellYourStuffImageFirstImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          sellYourStuffImageSecondImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          sellYourStuffImageThirdImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          sellYourStuffImageFourthImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          sellYourStuffImageFifthImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          sellYourStuffImageSixthImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `);

  const { nodes } = data.allContentfulHomePage;
  const nodeslang = filterLanguage(location, nodes);

  return (
    <section id="marketing-tools-section">
      {nodeslang.map((item) => (
        <div className="container" key={item.id}>
          <div className="row">
            <div className="col-12 marketing-tools-head-text">
              <h1>{item.sellYourStuffTitle}</h1>
            </div>
          </div>

          <div className="row pt-5 justify-content-center pl-md-5 pr-md-5">
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageFirstImage.fluid}
                  alt={item.sellYourStuffImageFirstImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageSecondImage.fluid}
                  alt={item.sellYourStuffImageSecondImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageThirdImage.fluid}
                  alt={item.sellYourStuffImageThirdImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageFourthImage.fluid}
                  alt={item.sellYourStuffImageFourthImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageFifthImage.fluid}
                  alt={item.sellYourStuffImageFifthImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-4 col-6 marketing-tools-image">
              <LazyLoad>
                <Img
                  fluid={item.sellYourStuffImageSixthImage.fluid}
                  alt={item.sellYourStuffImageSixthImage.file.fileName}
                />
              </LazyLoad>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center marketing-tools-cta-btn">
              <button
                onClick={() => scrollTo("#work-together")}
                className="btn"
              >
                Work Together
              </button>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default MarketingTools;
