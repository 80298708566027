/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import './_portfolio.scss'
import { filterLanguage } from '../../utils/language'

const Portfolio = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        nodes {
          node_locale
          id
          portfolioTitle
        }
      }
      allContentfulPortfolio(sort: { order: ASC, fields: projectType }) {
        nodes {
          id
          node_locale
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          name
          projectType
          websiteLink
        }
      }
    }
  `)

  const nodeslang = filterLanguage(location, data.allContentfulHomePage.nodes)
  const nodeslang2 = filterLanguage(
    location,
    data.allContentfulPortfolio.nodes,
  )

  return (
    <section id="portfolio">
      <div className="container">
        <div className="row justify-content-center">
          {nodeslang.map((item) => (
            <div className="col-md-7 portfolio-head" key={item.id}>
              <h1>{item.portfolioTitle}</h1>
            </div>
          ))}
        </div>

        <div className="row pt-5">
          {nodeslang2.map((item) => (
            <>
              {item.image.fluid !== null && (
                <div
                  className="col-md-6 pt-3 text-center portfolio-item"
                  key={item.id}
                >
                  <a href={item.websiteLink} target="_blank">
                    <div className="portfolio-image">
                      <LazyLoad>
                        <Img
                          fluid={item.image.fluid}
                          alt={item.image.file.fileName}
                        />
                      </LazyLoad>
                    </div>
                    <h3>{item.name}</h3>
                    <p>{item.projectType}</p>
                  </a>
                </div>
              )}
            </>
          ))}

          <div className="col-md-6 pt-3 text-center portfolio-item">
            <a href="#" target="_blank">
              <LazyLoad>
                <img
                  className="img-fluid"
                  src={require('../../../../static/home/our-portfolio/coming-soon.png')}
                  alt="Coming Soon"
                />
              </LazyLoad>
              <h3>Coming Soon</h3>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
