/* eslint-disable react/button-has-type */
import React from "react";
import LazyLoad from "react-lazyload";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useStaticQuery, graphql } from "gatsby";
import "./_technologies-we-know.scss";
import { filterLanguage } from "../../utils/language";
const TechnologiesWeKnow = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        nodes {
          id
          node_locale
          technologiesTitle
          technologyFirstImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          technologySecondImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
          technologyThirdImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `);

  const { nodes } = data.allContentfulHomePage;
  const nodeslang = filterLanguage(location, nodes);
  return (
    <section id="technologies">
      {nodeslang.map((item) => (
        <div className="container" key={item.id}>
          <div className="row">
            <div className="col-12 technologies-head-text">
              <h1>{item.technologiesTitle}</h1>
            </div>
          </div>

          <div className="row pt-5 pb-5 justify-content-center">
            <div className="col-md-3 technologies-image">
              <LazyLoad>
                <Img
                  fluid={item.technologyFirstImage.fluid}
                  alt={item.technologyFirstImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 technologies-image">
              <LazyLoad>
                <Img
                  fluid={item.technologySecondImage.fluid}
                  alt={item.technologySecondImage.file.fileName}
                />
              </LazyLoad>
            </div>
            <div className="col-md-3 technologies-image">
              <LazyLoad>
                <Img
                  fluid={item.technologyThirdImage.fluid}
                  alt={item.technologyThirdImage.file.fileName}
                />
              </LazyLoad>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center technologies-cta-btn">
              <button
                onClick={() => scrollTo("#work-together")}
                className="btn"
              >
                Work Together
              </button>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default TechnologiesWeKnow;
