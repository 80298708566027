/* eslint-disable react/prop-types */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { filterLanguage } from '../../utils/language'
import MakeWebsite from './make-website'
import IntegrateStuff from './integrate-stuff'
import DoSeo from './do-seo'
import Help from './help'
import './_what-we-do.scss'

const WhatWeDo = ({ location, isDocReady }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHomePage {
        nodes {
          id
          node_locale
          ourProcessTitle
          firstProcessTitle
          firstProcessDescription {
            childContentfulRichText {
              html
            }
          }

          secondProcessTitle
          secondProcessDescription {
            childContentfulRichText {
              html
            }
          }

          thirdProcessTitle
          thirdProcessDescription {
            childContentfulRichText {
              html
            }
          }

          fourthProcessTitle
          fourthProcessDescription {
            childContentfulRichText {
              html
            }
          }

        }
      }
    }
  `)

  const { nodes } = data.allContentfulHomePage
  const nodeslang = filterLanguage(location, nodes)

  return (
    <section id="what-we-do">
      {nodeslang.map((item) => (
        <div className="container" key={item.id}>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 what-we-do-head">
              <h1>{item.ourProcessTitle}</h1>
            </div>
          </div>

          <MakeWebsite item={item} isDocReady={isDocReady} />
          <IntegrateStuff item={item} isDocReady={isDocReady} />
          <DoSeo item={item} isDocReady={isDocReady} />
          <Help item={item} isDocReady={isDocReady} />
        </div>
      ))}
    </section>
  )
}

export default WhatWeDo
